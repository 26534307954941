.spinner {
    width: 40px;
    height: 40px;
    
    &.active:after {
        -webkit-animation: rotate 0.6s linear infinite;
        -moz-animation: rotate 0.6s linear infinite;
        -ms-animation: rotate 0.6s linear infinite;
        -o-animation: rotate 0.6s linear infinite;
        animation: rotate 0.6s linear infinite;
    }

    &:after {
        width: 40px;
        height: 40px;
        display: block;
        position: relative;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border-radius: 100%;
        border-top: 2px solid rgba(255,255,255,0.15);
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #ECECEC;
        border-right: 2px solid #ECECEC;
        content: '';
    }
}

@mixin transform ($transform) {
   transform: $transform;
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -o-transform: $transform;
}

@keyframes rotate {
  0% {
    @include transform(rotateZ(-360deg));
  }
  100% {
    @include transform(rotateZ(0deg));
  }
}

@-webkit-keyframes rotate {
  0% {
    @include transform(rotateZ(-360deg));
  }
  100% {
    @include transform(rotateZ(0deg));
  }
}

@-moz-keyframes rotate {
  0% {
    @include transform(rotateZ(-360deg));
  }
  100% {
    @include transform(rotateZ(0deg));
  }
}

@-o-keyframes rotate {
  0% {
    @include transform(rotateZ(-360deg));
  }
  100% {
    @include transform(rotateZ(0deg));
  }
}