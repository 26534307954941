[class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    color: #ffffff;
    fill: currentColor;
}

@mixin icon() {
    width: 25px;
    height: 25px;
    padding: 10px;
}
.icon-mobile {
    @include icon;
}
.icon-mail {
    @include icon;
}
.icon-creditcard {
    @include icon;
}
.icon-cross {
    @include icon;
}
.icon-info {
    @include icon;
}
