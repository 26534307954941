$lato-bold: 'latobold';
$lato-heavy: 'latoheavy';
$franklin-gothic-book: 'franklin_gothic_book';

@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato/lato-bold.eot');
    src: url('../fonts/lato/lato-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/lato-bold.woff2') format('woff2'),
         url('../fonts/lato/lato-bold.woff') format('woff'),
         url('../fonts/lato/lato-bold.ttf') format('truetype'),
         url('../fonts/lato/lato-bold.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoheavy';
    src: url('../fonts/lato/lato-heavy.eot');
    src: url('../fonts/lato/lato-heavy.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato/lato-heavy.woff2') format('woff2'),
         url('../fonts/lato/lato-heavy.woff') format('woff'),
         url('../fonts/lato/lato-heavy.ttf') format('truetype'),
         url('../fonts/lato/lato-heavy.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'franklin_gothic_book';
    src: url('../fonts/franklin-gothic/franklin-gothic-book.eot');
    src: url('../fonts/franklin-gothic/franklin-gothic-book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/franklin-gothic/franklin-gothic-book.woff') format('woff'),
         url('../fonts/franklin-gothic/franklin-gothic-book.ttf') format('truetype'),
         url('../fonts/franklin-gothic/franklin-gothic-book.svg#franklin_gothic_book') format('svg');
    font-weight: normal;
    font-style: normal;
}