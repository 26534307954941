@import "normalize";
@import "fonts";
@import "spinner";
@import "icons";

@mixin vertical-center() {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

html, body {
    width: 100%;
    height: 100%;
    background: #222;
    color: #fefefe;
    font-family: $franklin-gothic-book; 
}

h1, h2, h3 {
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin: 30px 0 15px;
    font-family: $lato-heavy;
    text-align: center;
    &:first-child {
        margin-top: 15px;
    }
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 18px;
}

p {
    line-height: 1.5;
    margin: 0 auto 15px;
    max-width: 565px;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.6);
    color: #c2c2c2;
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

#main-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #56564f; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  #56564f 0%, #2b2926 61%, #000000 100%); /* FF3.6+ */
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#56564f), color-stop(61%,#2b2926), color-stop(100%,#000000)); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(center, ellipse cover,  #56564f 0%,#2b2926 61%,#000000 100%); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover,  #56564f 0%,#2b2926 61%,#000000 100%); /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover,  #56564f 0%,#2b2926 61%,#000000 100%); /* IE10+ */
    background: radial-gradient(ellipse at center,  #56564f 0%,#2b2926 61%,#000000 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56564f', endColorstr='#000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.background-noise {
    background: url(../img/noise.png);
    width: 100%;
    height: 100%;
    opacity: 0.07;
}

#main {
    position: relative;
    padding: 5% 0;
}

.photos-container {
    width: 80%;
    max-width: 1060px;
    background: rgba(0,0,0,0.6);
    padding: 0 30px 30px;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0px 0px 15px #000;
    box-sizing: border-box;
}

.main-logo-header {
    padding: 37px 0;
}

.main-logo {
    display: block;
    max-width: 500px;
    margin: 0 auto;    
    
    img {
        display: block;
        width: 100%;
        height: auto;
    }
    
    // @breakpoint(645px) {
    //     margin: 50px 0;
    // }
}

.photo-list {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
    border: 7px solid #fff;
}

.photo-list__item {
    display: block;
    margin-top: 5px;

    &:first-child {
        margin-top: 0;
    }
}

.photo-list__img {
    width: 100%;
    height: auto;
}